<template>
  <div class="iq-sidebar sidebar-search">
    <div id="sidebar-scrollbar" class="w-100">
      <div class="pl-4 pr-4 w-100">
        <div class="d-flex justify-content-between">
          <h4 class="mb-0 line-height">Select language</h4>
          <b-button class="cursor-pointer hide-post ml-0" title="Hide" @click="hideSidebar">
            <i class="ri-close-line mr-0"></i>
          </b-button>
        </div>
        <p>Select the language you use on MeTwitt</p>
        <div>
          <GoogleTranslator :countries="countries"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryItems from '@/FackApi/json/TranslationCountries'
import GoogleTranslator from '@/components/homeiz/common/GoogleTranslator'

export default {
  name: 'SideBarLanguages',
  props: {
  },
  components: {
    GoogleTranslator
  },
  data () {
    return {
      countries: CountryItems
    }
  },
  computed: {
  },
  methods: {
    hideSidebar () {
      this.$emit('hide')
    }
  }
}
</script>
