<template>
    <b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName">
        <li
          v-for="(item,index) in items"
          :key="index"
          :class="item.is_heading ? 'iq-menu-title' : activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''"
          @click.stop="hideSidebar(item)"
        >
          <i v-if="item.is_heading" class="ri-subtract-line" />
            <span v-if="item.is_heading">{{ $t(item.name) }}</span>
            <router-link
              :to="item.link"
              v-if="!item.is_heading && !item.clickHandler"
              :class="`${activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''}`"
              v-b-toggle="item.name.replace('.', '-')"
            >
              <i :class="item.icon" class="mr-3" v-if="item.is_icon_class"/>
              <template v-else v-html="item.icon">
                <div v-if="item.icon" class="d-flex justify-content-center p-0 align-items-center mr-3">
                  <img :src="renderIco(item.icon)" class="menu-icon"/>
                </div>
              </template>

              <div v-if="item.class_name === 'profile'">
                <b-img rounded="circle" class="avatar-24 mr-3" fluid :src="avatar" :alt="item.name" />
              </div>

              <span :class="item.class_name">{{ $t(item.name) }}</span>

              <i v-if="item.children" class="ri-arrow-right-s-line iq-arrow-right" />
              <small v-html="item.append" :class="item.append_class" />
              <div v-if="item.counter" class="menu-notification-counter">
                  {{ item.counter }}
              </div>
            </router-link>
            <a
              v-else
              :class="`${activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''}`"
              v-b-toggle="item.name.replace('.', '-')"
              @click="handleEvent(item.clickHandler)"
            >
              <i :class="item.icon" class="mr-3" v-if="item.is_icon_class"/>
              <template v-else v-html="item.icon">
                <div v-if="item.icon" class="d-flex justify-content-center p-0 align-items-center mr-3">
                  <img :src="renderIco(item.icon)" class="menu-icon"/>
                </div>
              </template>
              <div class="d-flex align-items-center">
                <span :class="item.class_name">{{ $t(item.name) }}</span>
                <div v-if="item.counter" class="menu-notification-counter">
                  {{ item.counter }}
                </div>
                <span class="ml-3 pt-1" v-if="item.class_name === 'change-theme'">
                  <b-form-radio-group
                    size="sm"
                    class="d-flex align-items-center"
                    v-model="mode"
                    :options="[
                      { item: 'default',  name: 'Default '},
                      { item: 'dark', name: 'Dark Mode' }
                    ]"
                    value-field="item"
                    text-field="name"
                  />
                </span>
              </div>
            </a>
            <List
              v-if="item.children"
              :items="item.children"
              :open="item.link.name !== '' && activeLink(item) && item.children ? true : !!(item.link.name !== '' && activeLink(item))"
              :idName="item.name.replace('.', '-')"
              :accordianName="`sidebar-accordion${item.class_name}`"
              :className="`iq-submenu ${item.class_name}`"
            />
        </li>
    </b-collapse>
</template>
<script>
import List from './ListStyle1'
import { socialvue } from '../../../config/pluginInit'
import { mapActions } from 'vuex'
import * as authActions from '@/store/modules/auth/types/actions'

export default {
  name: 'List',
  props: {
    items: Array,
    className: { type: String, default: 'iq-menu' },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: 'sidebar' },
    accordianName: { type: String, default: 'sidebar' }
  },
  components: {
    List
  },
  inject: ['authUser'],
  mounted () {
  },
  data () {
    return {
      defaultAvatar: require('@/assets/images/user/04.jpg'),
      darkMode: false,
      mode: 'default',
      showSearch: false,
      showLanguages: false,
      showNotifications: false
    }
  },
  watch: {
    mode: {
      handler: function (val, oldVal) {
        this.changeTheme()
      }
    }
  },
  computed: {
    calculateWhoViewed () {
      return 5
    },
    calculateReferrals () {
      return 10
    },
    calculateRecommendations () {
      return 6
    },
    calculateFaceToFace () {
      return 12
    },
    avatar () {
      return this.authUser.avatar ? this.authUser.avatar : this.defaultAvatar
    }
  },
  methods: {
    ...mapActions('auth', {
      signOut: authActions.SIGN_OUT
    }),
    ...mapActions({
      modeChange: 'settings/layoutModeAction',
      showSearchAction: 'settings/showSearchAction',
      showLanguagesAction: 'settings/showLanguagesAction',
      showNotificationsAction: 'settings/showNotificationsAction'
    }),
    activeLink (item) {
      return socialvue.getActiveLink(item, this.$route.name)
    },
    getComputed (name) {
      return this[name]
    },
    renderIco (ico) {
      return require(`@/assets/images${ico}`)
    },
    handleEvent (name) {
      this.showSearchAction(false)
      this[name]()
    },
    giveFeedback () {
      this.$bvModal.show('feedback-modal')
    },
    deleteAccount () {
      this.$bvModal.show('close-account-modal')
    },
    async onSignOut () {
      await this.signOut()
      this.$router.go()
    },
    themeMode (mode) {
      this.dark = mode
      this.modeChange({ dark: mode })
    },
    changeTheme () {
      this.darkMode = !this.darkMode
      this.themeMode(this.darkMode)
    },
    showSearchHandler () {
      this.showSearch = !this.showSearch
      this.showSearchAction(this.showSearch)
      const menuItems = document.querySelectorAll('ul#sidebar li')
      if (this.showSearch) {
        menuItems.forEach(item => {
          item.classList.remove('active')
        })
        menuItems[1].classList.add('active')
      } else {
        menuItems[1].classList.remove('active')
      }
    },
    hideSidebar (item) {
      if (item.name !== 'sidebar.search') {
        this.showSearch = false
        this.showSearchAction(false)
      }

      if (item.name !== 'sidebar.languages') {
        this.showLanguages = false
        this.showLanguagesAction(false)
      }

      if (item.name !== 'sidebar.notifications') {
        this.showNotifications = false
        this.showNotificationsAction(false)
      }
    },
    showLanguagesHandler () {
      this.showLanguages = !this.showLanguages
      this.showLanguagesAction(this.showLanguages)
      const menuItems = document.querySelectorAll('ul#sidebar li')
      if (this.showLanguages) {
        menuItems.forEach(item => {
          item.classList.remove('active')
        })
        menuItems[10].classList.add('active')
      } else {
        menuItems[10].classList.remove('active')
      }
    },
    showNotificationsHandler () {
      this.showNotifications = !this.showNotifications
      this.showNotificationsAction(this.showNotifications)
      const menuItems = document.querySelectorAll('ul#sidebar li')
      if (this.showNotifications) {
        menuItems.forEach(item => {
          item.classList.remove('active')
        })
        menuItems[8].classList.add('active')
      } else {
        menuItems[8].classList.remove('active')
      }
    },
    createVidsHandler () {
      this.$bvModal.show('files-modal-vids')
    },
    void () {

    }
  }
}
</script>

<style lang="scss">
.menu-icon {
  width: 24px;
}

.vids-colorfull-menu {
  max-height: 24px;
}
</style>
