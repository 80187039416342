export const POSTING = 1
export const INTEREST_GROUPS = 2
export const VIDS = 3
export const MESSANGER = 4
export const PHOTO = 5
export const VIDEOS = 6
export const COMPANY_PAGE = 7
export const REPOST = 8
export const FAKE_NEWS = 9
export const NOTIFICATIONS = 10
export const LOGIN = 11
export const SEARCH = 12
export const ADS = 13
export const POST_A_JOB = 14
export const PRIVACY = 15
export const WHOS_VIEWED_YOU = 16
export const GEO_LOCATION_TARGETING_POST = 17
export const TWEETS_NOTIFICATION = 18

export const POSTING_TEXT = 'Posting'
export const INTEREST_GROUPS_TEXT = 'Iterest groups'
export const VIDS_TEXT = 'Vids'
export const MESSANGER_TEXT = 'Messanger'
export const PHOTO_TEXT = 'Photo'
export const VIDEOS_TEXT = 'Videos'
export const COMPANY_PAGE_TEXT = 'Company page'
export const REPOST_TEXT = 'Repost'
export const FAKE_NEWS_TEXT = 'Fake news'
export const NOTIFICATIONS_TEXT = 'Notifications'
export const LOGIN_TEXT = 'Login'
export const SEARCH_TEXT = 'Search'
export const ADS_TEXT = 'Ads'
export const POST_A_JOB_TEXT = 'Post a job'
export const PRIVACY_TEXT = 'Privacy'
export const WHOS_VIEWED_YOU_TEXT = 'Who\'s viewed you'
export const GEO_LOCATION_TARGETING_POST_TEXT = 'Geo location targeting post'
export const TWEETS_NOTIFICATION_TEXT = 'Tweets notification'

export const FeedbackFeatureOptions = [
  { value: POSTING, text: POSTING_TEXT },
  { value: INTEREST_GROUPS, text: INTEREST_GROUPS_TEXT },
  { value: VIDS, text: VIDS_TEXT },
  { value: MESSANGER, text: MESSANGER_TEXT },
  { value: PHOTO, text: PHOTO_TEXT },
  { value: VIDEOS, text: VIDEOS_TEXT },
  { value: COMPANY_PAGE, text: COMPANY_PAGE_TEXT },
  { value: REPOST, text: REPOST_TEXT },
  { value: FAKE_NEWS, text: FAKE_NEWS_TEXT },
  { value: NOTIFICATIONS, text: NOTIFICATIONS_TEXT },
  { value: LOGIN, text: LOGIN_TEXT },
  { value: SEARCH, text: SEARCH_TEXT },
  { value: ADS, text: ADS_TEXT },
  { value: POST_A_JOB, text: POST_A_JOB_TEXT },
  { value: PRIVACY, text: PRIVACY_TEXT },
  { value: WHOS_VIEWED_YOU, text: WHOS_VIEWED_YOU_TEXT },
  { value: GEO_LOCATION_TARGETING_POST, text: GEO_LOCATION_TARGETING_POST_TEXT },
  { value: TWEETS_NOTIFICATION, text: TWEETS_NOTIFICATION_TEXT }
]
