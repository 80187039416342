<template>
  <div class="iq-sidebar">
    <router-link class="iq-navbar-logo-svg" :to="homeURL">
      <div id="logo-wrapper">
        <img class="img-fluid" :src="logo" title="MeTwitt" alt="MeTwitt"/>
      </div>
    </router-link>
    <div id="sidebar-scrollbar">
        <nav class="iq-sidebar-menu">
           <List
              :items="items"
              :open="true"
              :horizontal="horizontal"
              @onSearch="miniSidebar"
            />
        </nav>
        <div class="p-0"></div>
        <div class="m-2 d-flex w-100">
          <img class="img-fluid cursor-pointer" src="@/assets/images/buttons/tweet.svg" title="Tweet" alt="Tweet" @click="createTweet"/>
          <!-- <button aria-label="Tweet" type="submit" class="btn btn-tweet w-60 mt-1 mr-3" v-b-modal.post-modal>
            Tweet
          </button> -->
        </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import * as feedActions from '@/store/modules/feed/types/actions'
import List from '../menus/ListStyle1'
export default {
  name: 'SideBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'social.feed' }) },
    items: { type: Array },
    logo: { type: String, default: require('@/assets/images/metwitt-logo-small.svg') },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true }
  },
  components: {
    List
  },
  methods: {
    ...mapActions('feed', {
      setCreatePeepModalState: feedActions.SET_CREATE_PEEP_MODAL_STATE
    }),
    miniSidebar () {
      this.$emit('toggle')
    },
    createTweet () {
      if (this.$route.name === 'social.feed') {
        this.$bvModal.show('post-modal')
      } else {
        this.setCreatePeepModalState(true)
        this.$router.push({ name: 'social.feed' })
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>
