<template>
  <transition name="fade">
    <div id="scroll-top" class="position-fixed" v-show="scY > 300" @click="toTop">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none"
           stroke="#ffffff"
           stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
        <path d="M18 15l-6-6-6 6"/>
      </svg>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ScrollToTop',
  data () {
    return {
      scTimer: 0,
      scY: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  #scroll-top {
    bottom: 20px;
    right: 352px;
    background-color: var(--iq-martin-blue-text);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 999999;

    &:hover {
      background-color: #cfd4d4;
    }
  }
</style>
