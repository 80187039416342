<template>
  <div class="iq-sidebar sidebar-search">
    <div id="sidebar-scrollbar" class="w-100">
      <div class="pl-4 pr-4 w-100">
        <div class="d-flex justify-content-between">
          <h4 class="mb-0 line-height notifications">Notifications</h4>
          <b-button class="cursor-pointer hide-post ml-0" title="Hide" @click="hideSidebar">
            <i class="ri-close-line mr-0"></i>
          </b-button>
        </div>
      </div>
      <div class="mt-4 pl-2 pr-2 w-100">
        <div>
          <div class="mb-4">
            <div class="">
                <ul id="peep-filter-tab"
                    class="nav nav-pills d-flex align-items-center text-center profile-forum-items p-0 m-0 justify-content-between">
                    <li v-for="(tab, index) in tabs" :key="index" class="p-0">
                        <a
                            class="pl-0 pr-0 pb-0"
                            :class="tab.classname"
                            data-toggle="pill"
                            :href="tab.link"
                        >
                            {{ tab.title }}
                        </a>
                    </li>
                </ul>
            </div>
          </div>
      </div>
      <div v-for="(item) in items" :key="item.id">
        <router-link to="/"  class="iq-sub-card">
            <div class="media">
                <div class="">
                    <img class="avatar-50 rounded" :src="item.userAvatar" :alt="item.userName">
                </div>
                <div class="media-body ml-3 mb-3">
                    <h6 class="mb-0 font-10-pt text-bold">{{ item.userName }}</h6>
                    <div class="font-size-12">{{ item.text }}</div>
                    <div class="text-martin text-bold font-size-10-pt">{{ item.time }}</div>
                </div>
                <div class="">
                  <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0" toggle-class="p-0">
                    <template v-slot:button-content>
                      <b-link href="javascript:void(0)" class="text-secondary"><i
                        class="ml-3 ri-more-line"></i></b-link>
                    </template>
                    <a href="javascript:void(0)" class="dropdown-item p-2">
                      <div class="d-flex align-items-center">
                        <div class="icon font-size-20"><i class="ri-check-line"></i></div>
                        <div class="data ml-2">
                          <h6>Mark as unread</h6>
                        </div>
                      </div>
                    </a>
                    <a href="javascript:void(0)" class="dropdown-item p-2">
                      <div class="d-flex align-items-center">
                        <div class="icon font-size-20"><i class="ri-user-unfollow-line"></i></div>
                        <div class="data ml-2">
                          <h6>Block {{ item.userName }}</h6>
                        </div>
                      </div>
                    </a>
                    <a href="javascript:void(0)" class="dropdown-item p-2">
                      <div class="d-flex align-items-center">
                        <div class="icon font-size-20"><i class="ri-delete-bin-line"></i></div>
                        <div class="data ml-2">
                          <h6>Delete notification</h6>
                        </div>
                      </div>
                    </a>
                  </b-dropdown>
                </div>
            </div>
        </router-link>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Notifications from '@/FackApi/api/Notifications'

export default {
  name: 'SideBarNotifications',
  props: {
  },
  components: {
  },
  data () {
    return {
      items: Notifications
    }
  },
  computed: {
    tabs () {
      return [
        {
          classname: 'nav-link active',
          headerName: 'New',
          link: '#notofications-new',
          title: 'New'
        },
        {
          classname: 'nav-link',
          headerName: 'Earlier',
          link: '#notofications-new',
          title: 'Earlier'
        }
      ]
    }
  },
  methods: {
    hideSidebar () {
      this.$emit('hide')
    }
  }
}
</script>
