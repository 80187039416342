<template>
  <div class="iq-sidebar sidebar-search">
    <div id="sidebar-scrollbar" class="w-100">
      <div class="pl-4 pr-4 w-100">
        <div class="d-flex justify-content-between">
          <h4 class="mb-0 line-height notifications">Search</h4>
          <b-button class="cursor-pointer hide-post ml-0" title="Hide" @click="hideSidebar">
            <i class="ri-close-line mr-0"></i>
          </b-button>
        </div>
        <form action="#" class="searchbox search-box-user d-flex position-relative align-items-center mt-2 mb-4">
          <input v-model="search" type="text" class="form-control router-link-active search-input" placeholder="Search MeTwitt...">
          <div class="comment-attagement d-flex">
            <b-link href="javascript:void(0);">
              <i class="ri-search-line mr-2"></i>
            </b-link>
          </div>
        </form>
        <div class="d-flex mt-4 align-items-center justify-content-between">
          <h6>Recent</h6>
          <b-button variant="text" class="w-30 font-10-pt" @click="clearSearch">Clear All</b-button>
        </div>
        <div>
          <div v-for="(item,index) in filteredItems" :key="index" class="media cursor-pointer align-items-center mb-2">
            <div class="iq-profile-avatar status-online">
              <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
            </div>
            <div class="media-body ml-3">
              <h6 class="mb-0 font-10-pt text-bold">{{item.fullName}}</h6>
              <p class="mb-0 font-9-pt text-bold font-color-rgb-140">{{item.time}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '@/store/modules/chat-panel/types/getters'

export default {
  name: 'SideBarSearch',
  props: {
  },
  components: {
  },
  methods: {
    clearSearch () {
      this.clear = true
    },
    hideSidebar () {
      this.$emit('hide')
    }
  },
  data () {
    return {
      search: '',
      clear: false
    }
  },
  computed: {
    ...mapGetters('chatPanel', {
      users: getters.GET_BUSINESS_USERS
    }),
    filteredItems () {
      if (!this.clear) {
        return Object.values(this.users).filter(item => {
          return item.fullName.toLowerCase().includes(this.search.toLowerCase())
        })
      } else {
        return []
      }
    }
  }
}
</script>
