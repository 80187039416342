import User from '@/Model/User'
import Message from '@/Model/Message'

export const Users = [
  new User({ id: 1, firstName: 'Anna Sthesia', role: 'Developer', isPrivate: true, avatar: require('@/assets/images/user/user-01.jpg'), isActive: true }),
  new User({ id: 2, firstName: 'Paul Molive', role: 'Web Designer', avatar: require('@/assets/images/user/user-05.jpg'), isActive: false }),
  new User({ id: 3, firstName: 'Bob Frapples', role: 'App Developer', isPrivate: true, avatar: require('@/assets/images/user/user-03.jpg'), isActive: true }),
  new User({ id: 4, firstName: 'Barb Ackue', role: 'Ios Developer', avatar: require('@/assets/images/user/user-04.jpg'), isActive: true }),
  new User({ id: 5, firstName: 'Greta Life', role: 'Game Developer', avatar: require('@/assets/images/user/user-05.jpg'), isActive: false }),
  new User({ id: 6, firstName: 'Ira Membrit', role: 'Software Developer', avatar: require('@/assets/images/user/user-06.jpg'), isActive: true }),
  new User({ id: 7, firstName: 'Pete Sariya', role: 'Backend Developer', avatar: require('@/assets/images/user/user-07.jpg'), isActive: false }),
  new User({ id: 8, firstName: 'Anna Sthesia', role: 'Web Developer', avatar: require('@/assets/images/user/user-08.jpg'), isActive: true }),
  new User({ id: 9, firstName: 'Paul Molive', role: 'App Developer', avatar: require('@/assets/images/user/user-09.jpg'), isActive: false }),
  new User({ id: 10, firstName: 'Bob Frapples', role: 'Ios Developer', avatar: require('@/assets/images/user/user10.jpg'), isActive: true }),
  new User({ id: 11, firstName: 'Anna Sthesia', role: 'Web Designer', avatar: require('@/assets/images/user/user-01.jpg'), isActive: true }),
  new User({ id: 12, firstName: 'Paul Molive', role: 'Game Developer', avatar: require('@/assets/images/user/user-05.jpg'), isActive: false }),
  new User({ id: 13, firstName: 'Bob Frapples', role: 'Web Developer', avatar: require('@/assets/images/user/user-03.jpg'), isActive: false }),
  new User({ id: 14, firstName: 'Barb Ackue', role: 'Ios Developer', avatar: require('@/assets/images/user/user-04.jpg'), isActive: true }),
  new User({ id: 15, firstName: 'Greta Life', role: 'App Developer', avatar: require('@/assets/images/user/user-08.jpg'), isActive: true }),
  new User({ id: 16, firstName: 'Greta Life', role: 'App Developer', avatar: require('@/assets/images/user/user-08.jpg'), isActive: true }),
  new User({ id: 17, firstName: 'Greta Life', role: 'App Developer', avatar: require('@/assets/images/user/user-08.jpg'), isActive: true }),
  new User({ id: 18, firstName: 'Greta Life', role: 'App Developer', avatar: require('@/assets/images/user/user-08.jpg'), isActive: true }),
  new User({ id: 19, firstName: 'Greta Life', role: 'App Developer', avatar: require('@/assets/images/user/user-08.jpg'), isActive: true }),
  new User({ id: 20, firstName: 'Greta Life', role: 'App Developer', avatar: require('@/assets/images/user/user-08.jpg'), isActive: true }),
  new User({ id: 21, firstName: 'Greta Life', role: 'App Developer', avatar: require('@/assets/images/user/user-08.jpg'), isActive: true })
]

export const MessagesUser1 = [
  new Message({ text: 'How can we help? We\'re here for you! 😄', userId: 5, me: true, time: '6:45' }),
  new Message({ text: 'Hey John, I am looking for the best admin template. Could you please help me to find it out?🤔', userId: 15, me: false, time: '6:48' }),
  new Message({ text: 'Absolutely!\n' + 'SocialV Dashboard is the responsive bootstrap 4 admin template.', userId: 5, me: true, time: '6:50' }),
  new Message({ text: 'Looks clean and fresh UI.', userId: 15, me: false, time: '6:55' }),
  new Message({ text: 'Thanks, from ThemeForest.', userId: 5, me: true, time: '6:59' }),
  new Message({ text: 'I will purchase it for sure.', userId: 15, me: false, time: '7:05' }),
  new Message({ text: 'Okay Thanks...', userId: 5, me: true, time: '7:07' }),
  new Message({ text: 'Hey John, I am looking for the best admin template. Could you please help me to find it out?', userId: 15, me: false, time: '7:08' }),
  new Message({ text: 'Absolutely!\n' + 'SocialV Dashboard is the responsive bootstrap 4 admin template.', userId: 5, me: true, time: '7:10' }),
  new Message({ text: 'Looks clean and fresh UI.', userId: 15, me: false, time: '7:12' }),
  new Message({ text: 'Okay Thanks...', userId: 5, me: true, time: '7:20' }),
  new Message({ text: 'How can we help? We\'re here for you! 😄', userId: 5, me: true, time: '6:45' }),
  new Message({ text: 'Hey John, I am looking for the best admin template. Could you please help me to find it out?🤔', userId: 15, me: false, time: '6:48' }),
  new Message({ text: 'Absolutely!\n' + 'SocialV Dashboard is the responsive bootstrap 4 admin template.', userId: 5, me: true, time: '6:50' }),
  new Message({ text: 'Looks clean and fresh UI.', userId: 15, me: false, time: '6:55' }),
  new Message({ text: 'Thanks, from ThemeForest.', userId: 5, me: true, time: '6:59' }),
  new Message({ text: 'I will purchase it for sure.', userId: 15, me: false, time: '7:05' }),
  new Message({ text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu bibendum purus. Phasellus lacinia metus lectus, at ultrices erat cursus vel. Nam interdum bibendum ornare. Sed at ornare nisi. Praesent porttitor elit eget justo auctor, ut aliquam libero blandit. Aenean euismod auctor nunc, non vestibulum enim. In nunc enim, blandit ut pulvinar eu, euismod eget lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse sit amet dolor tempus, fermentum ex et, tincidunt nibh. Donec vel diam lectus. Sed dignissim risus sit amet elit porttitor ultricies. Suspendisse eget augue orci. In id ligula nec sapien consectetur ornare. Sed et ante a nisl tincidunt hendrerit. Fusce diam massa, blandit nec rutrum vel, cursus in velit. Curabitur lectus felis, hendrerit quis mi a, efficitur pulvinar elit.', userId: 5, me: true, time: '7:07' }),
  new Message({ text: 'Hey John, I am looking for the best admin template. Could you please help me to find it out?', userId: 15, me: false, time: '7:08' }),
  new Message({ text: 'Absolutely!\n' + 'SocialV Dashboard is the responsive bootstrap 4 admin template.', userId: 5, me: true, time: '7:10' }),
  new Message({ text: 'Looks clean and fresh UI.', userId: 15, me: false, time: '7:12' }),
  new Message({ text: 'Okay Thanks...', userId: 5, me: true, time: '7:20' })
]

export const MessagesUser2 = [
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message()
]

export const MessagesUser3 = [
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message()
]
export const MessagesUser4 = [
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message(),
  new Message()
]
