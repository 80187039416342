<template>
  <main>
    <h5 class="mb-4 line-height">{{ authUser.firstName }}, we're sorry to see you go</h5>
    <p>
      Just a quick reminder, closing your account means you'll lose touch with all of your followers connections.
      You'll also lose any likes you have given or received. No refunds will be made to you.
    </p>
    <p>
      Should you want to return to MeTwitt in the future you will need to create a new account.
    </p>
    <hr>
    <p class="text-small">Tell us the reason for closing your account:</p>
    <b-form-radio-group
      v-model="reason"
      :options="reasonOptions"
      name="reason"
      stacked
    ></b-form-radio-group>
    <hr>
    <h6>Close account</h6>
    <div class="form-group position-relative">
      <label>Enter your password to close this account</label>
      <input :type="showPassword ? 'text' : 'password'" v-model="password" class="form-control mb-0"
        id="password" placeholder="Password">
      <div class="password-show-button" @click="showPassword = !showPassword">
        <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
          class="bi bi-eye" viewBox="0 0 16 16">
          <path
            d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-eye-slash"
          viewBox="0 0 16 16">
          <path
            d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
          <path
            d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
          <path
            d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
        </svg>
      </div>
      <div class="invalid-feedback d-block" v-if="!$v.password.required && formSubmitted">
        Please enter password
      </div>
      <b-form-checkbox
        id="unsubscribe"
        class="mt-4"
        v-model="unsubscribe"
        name="unsubscribe"
        value="unsubscribe"
        unchecked-value="subscribe"
      >
        Unsubscribe me from MeTwitt email communication.
      </b-form-checkbox>
      <div>
        <button class="btn btn-primary d-block w-100 mt-3">
          Close your MeTwitt account
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CloseAccountModal',
  inject: ['authUser'],
  validations: {
    password: { required }
  },
  data () {
    return {
      password: null,
      formSubmitted: false,
      showPassword: false,
      reason: null,
      unsubscribe: false,
      reasonOptions: [
        { text: 'I\'m getting to many emails', value: 'I\'m getting to many emails' },
        { text: 'I\'m not getting any value from my membership', value: 'I\'m not getting any value from my membership' },
        { text: 'I have a privacy concern', value: 'I have a privacy concern' },
        { text: 'I\'m receiving unwanted contact', value: 'I\'m receiving unwanted contact' },
        { text: 'Other', value: 'Other' }
      ]
    }
  }
}
</script>

<style lang="scss">
.password-show-button {
  top: 36px;
}
</style>
