<template>
  <main>
    <h5 class="mb-4 line-height">We'd love to hear about your experience with us. You can leave us feedback.</h5>
    <hr>
    <form class="feedback-page main-form">
      <div>
        <give-feedback-feature-select v-model="form.feature" :submitted="formSubmitted" required />
      </div>
      <div>
        <form-text-area-limited
          v-model="form.feedback"
          label="Your feedback"
          error-message="Please enter your feedback"
          :limit="2000"
          :submitted="formSubmitted"
          required
        />
      </div>
    </form>
    <hr>
    <div>
      <p>
        Thank you so much for your feedback about MeTwitt feature. We really appreciate the details you shared with us
        about areas we can improve. This insight will help us greatly as we approach our next features. We appreciate your
        guidance and hope we can find more feedback opportunities soon.
      </p>
      <div>
        <button class="btn btn-primary d-block w-100 mt-3">
         Send
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import GiveFeedbackFeatureSelect from '@/components/homeiz/common/form/menu/GiveFeedbackFeatureSelect.vue'
import FormTextAreaLimited from '@/components/homeiz/common/form/FormTextAreaLimited.vue'

export default {
  components: {
    GiveFeedbackFeatureSelect,
    FormTextAreaLimited
  },
  name: 'GiveFeedbackModal',
  inject: ['authUser'],
  validations: {
    form: {
      feature: { required },
      feedback: { required }
    }
  },
  data () {
    return {
      formSubmitted: false,
      form: {
        feature: null,
        feedback: null
      }
    }
  }
}
</script>

<style lang="scss">
.feedback-page {
  textarea {
    border-radius: 20px !important;
  }
}
</style>
